@import "../node_modules/bootstrap/scss/bootstrap";
@import "/src/const.scss";

@import '/src/progmatic/main/main.scss';
@import '/src/progmatic/footer/footer.scss';
@import '/src/progmatic/header/header.scss';
@import '/src/progmatic/about/about.scss';
@import '/src/progmatic/technologies/technologies.scss';
@import '/src/progmatic/vacancy/vacancy.scss';
@import '/src/progmatic/partners/patners.scss';
@import '/src/progmatic/contacts/contacts.scss';
@import '/src/progmatic/sout/sout.scss';
@import '/src/progmatic/products/products.scss';


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

#root {
    height: 100vh;
    font-family:'Roboto', sans-serif !important;
    overflow-x: hidden;
}

body {
    font-size: 16px;
}


.mw-2000 {
    max-width: 2000px;
}

.h-100 {
    height: 100% !important;
}

.vh-100 {
    height: 100vh !important;
}


// text color
.red1-text {
    color: $red1
}


//bg color
.bg-red1 {
    background-color: $red1
}

.bg-beige {
    background-color: $beige;
}

.bg-gray {
    background-color: $gray-light;
}


//flex
.d-flex-center {
    @extend .d-flex;
    justify-content: center !important;
    align-items: center !important;
}

.d-flex-between {
    @extend .d-flex;
    justify-content: space-between !important;
}


//big fonts
.display-1 { 
    @extend .display-1;
    font-weight: 400;
}

.display-7 {
    @extend .display-1;
    font: {
        size: calc(1.375rem + 0.5vw) !important;
        weight: 300 !important;
    }
}

.display-8 {
    @extend .display-1;
    font: {
        size: calc(1rem + 0.4vw) !important;
        weight: 600 !important;
    }
}

.display-9 {
    @extend .display-1;
    font: {
        size: calc(1rem + 0.2vw) !important;
        weight: 400 !important;
    }
}

.zindex-sticky {
    z-index: 100;
}


//big margin
.m-big {
    margin: 6rem;
}

.my-big {
    margin: {
        top: 6rem;
        bottom: 6rem;
    }
}

.mx-big {
    margin: {
        left: 6rem;
        right: 6rem;
    }
}

.mt-big {
    margin-top: 6rem;
}


//positions
.p-absolute {
    position: absolute !important;
}

.p-relative {
    position: relative !important;
}


//library styles
.swiper-pagination-bullet-active {
    background-color: #AC2F33 !important;
}


.smoothness {
    transition: 0.3s
}

.fw-400 {
    font-weight: 400 !important;
}

.icon-size {
    height: 2rem !important;
    width: 2rem !important;
}


@media screen and (max-width: 700px) {
    .p-5 { padding: 1rem !important }

    .px-5 { padding: {
            right: 1rem !important; 
            left: 1rem !important; 
        }
    }

    .py-5 { padding: {
            top: 1rem !important; 
            bottom: 1rem !important; 
        }
    }
 
    .m-5, .m-4 { margin: 1rem !important }

    .mx-5, .mx-4 { 
        margin: {
            right: 1rem !important; 
            left: 1rem !important; 
        }
    }

    .m-big {
        margin: 1.5rem;
    }
    
    .my-big {
        margin: {
            top: 1.5rem;
            bottom: 1.5rem;
        }
    }
    
    .mx-big {
        margin: {
            left: 1.5rem;
            right: 1.5rem;
        }
    }
    
    .mt-big {
        margin-top: 1.5rem;
    }
}
